@login-full-width: 380px;

.login-solo-form {
    width: 100%;

    @media (min-width: (@login-full-width + @grid-gutter-width)) {
        padding: 30px;
        width: @login-full-width;
    }

    box-shadow: 0 2px 10px 0px rgba(100,100,100,.75);
}

.login-forgotten-password, .login-forgotten-username {
    float: right;
    font-weight: normal;
}

.login-wrapper {
    margin: 130px auto;

    /** Removes some of the verticality on short viewports */
    @media (max-height: @screen-xs-max) {
        margin: 30px auto;
    }
}

.login-register {
    padding: 15px;

    @media (min-width: (@login-full-width + @grid-gutter-width)) {
        width: @login-full-width;
        padding: 15px 30px;
    }

    margin-top: 15px;
    background: rgba(255, 255, 255, .5);
    box-shadow: 0 2px 10px 0px rgba(100,100,100,.25);
}
