.policy {
    @margin: 40px;

    ol {
        counter-reset: list;
        margin-left: @margin * 1.5;
        padding-left: 0;

        li {
            list-style: none;

            &:before {
                content: counter(list, lower-alpha) ") ";
                counter-increment: list;
                position: absolute;
                left: 34px;
            }
        }
    }

    h4 {
        margin-top: @margin;
    }

    h3, h4, p {
        position: relative;
        margin-left: @margin;

        > i {
            font-style: normal;
            position: absolute;
            left: @margin * -1;
        }
    }
}
