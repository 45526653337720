/**
 * Generate spacer mixins to enable consistent spacing between elements.
 *
 * Usage (replace # with number of line-heights to replace with):
 * Add `.spacer(#)` to add equal top and bottom spacing to a class
 * Add `.spacer-bottom(#)` to add bottom spacing to a class
 * Add `.spacer-top(#)` to add top spacing to a class
 */
.spacer(@rows: 1) {
    .spacer-top(@rows);
    .spacer-bottom(@rows);
}

.spacer-top(@rows: 1) {
    margin-top: (@rows * @line-height-computed);
}

.spacer-bottom(@rows: 1) {
    margin-bottom: (@rows * @line-height-computed);
}
