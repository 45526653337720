.skip-link {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: 0 !important;
    overflow: hidden !important;
    clip: rect(0 0 0 0) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important;
    text-decoration: underline;
    text-decoration-thickness: max(1px, .0625rem);
    text-underline-offset: .1em;
    line-height: 1.14286;
    display: block;
    padding: 10px 15px;
    text-align: center;
}

.skip-link:focus {
    outline: 3px solid @milk-complementary;
    outline-offset: 0;
    background-color: @milk-complementary;
}

.skip-link:active, .skip-link:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    margin: inherit !important;
    overflow: visible !important;
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    white-space: inherit !important;
}
