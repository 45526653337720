.contact-background(@img) {
    background: url('../../img/@{img}.svg') 10px center no-repeat;
}

.contact-details {
    .content-box;
    margin: (@grid-gutter-width / 2) 0;

    ul {
        padding-left: 0;
        list-style: none;

        li {
            padding: 10px 0 10px 35px;
            .font-size(1.8);
        }

    }

    &__email {
        padding-left: 40px;
        .contact-background(contact-details-email);
        .text-overflow;
    }

    &__name {
        padding-left: 40px;
        .contact-background(contact-details-name);
    }

    &__phone {
        padding-left: 40px;
        .contact-background(contact-details-phone);
    }

    &__mobile {
        padding-left: 40px;
        .contact-background(contact-details-mobile);
    }

    @media (min-width: @screen-md-min) {
        margin: 0;
    }
}
