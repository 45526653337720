.anchor-color(@normal, @focus) {
    color: @normal;

    &:focus, &:hover {
        color: @focus;
    }
}

.font-size(@sizeValue) {
    @remValue: @sizeValue;
    @pxValue: (@sizeValue * 10);
    font-size: ~"@{pxValue}px";
    font-size: ~"@{remValue}rem";
}
