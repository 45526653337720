.table {
    // Override the default caption text colour to ensure contrast
    > caption {
        color: @text-color;
    }

    // Allow an enpty td in a header row to have the correct border
    thead > tr > td {
        border-bottom: 2px solid #DDD;
    }
}
