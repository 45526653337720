@import "shared/login-banner.less";

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    background-color: @milk-light;
    font-size: 16px;
}

body > main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    & > .container {
        flex: 1 0 auto;

        background-color: @milk-background;

        @media (max-width: @screen-xs-max) {
            width: 100%;
        }

        &--unflex {
            flex: none;
        }
    }
}

.container--flex {
    display: flex;

    // Disables the sticky footer on short viewports
    @media (max-height: @screen-xs-max) {
        display: block;
    }
}

.container {
    &--flex-column.container {
        .container--flex;
        flex-direction: column;
        flex: auto;
    }

    &--auth {
        padding-top: 20px;
    }
}

// Align content in the middle of the container, combine with .container--flex.
.aligner {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

p a {
    font-weight: bold;
}

a.inverse {
    .anchor-color(@milk-background; @milk-complementary);
}

a.underline {
    text-decoration: underline;
}

a[data-toggle],
a[data-toggle]:active,
a[data-toggle]:focus {
    .anchor-color(@milk-main; @milk-main);
    text-decoration: none;
}

hr {
    border-top: 1px solid @milk-light;
}

.pattern-background {
    background: url('../img/light-pattern.png');
}

.pattern-background-dark {
    background: url('../img/dark-pattern.png');
}

.dark-pattern-block {
    .pattern-background-dark;
    color: @milk-background;
    padding: 10px;
}

.title-box-primary {
    background-color: fade(@milk-main, 75%);
    color: @milk-background;
    padding: 15px;

    h1, h2, h3, h4 {
        color: inherit;
        margin-top: 0;
    }

    a {
        .anchor-color(@milk-complementary; @milk-background);

        text-decoration: underline;

        &.inverse {
            .anchor-color(@milk-background; @milk-complementary);
        }
    }

    p:last-child {
        margin-bottom: 0;
    }

    p a {
        font-weight: 500;
    }
}

.warning-bar {
    background: url("../img/blue-exclamation.svg") no-repeat;
    min-height: 26px;
    padding-left: 35px;
    padding-top: 2px;
}

.format-breakword {
    word-break: break-all;
}

@media (min-width: @screen-sm-min) {
    .collapse {
        padding-left: 0;
    }

    .row-top-spaced {
        .spacer-top(2);
    }

    .row-top-small-spaced {
        .spacer-top(1);
    }

    .row-large-spaced {
        .spacer-top(4);
    }

    .row-bottom-spaced {
        .spacer-bottom(2);
    }

    .row-bottom-small-spaced {
        .spacer-bottom(1);
    }

    .row-bottom-large-spaced {
        .spacer-bottom(4);
    }
}

.alert--global {
    border-radius: 0;
    margin: 0;
}

.alert--notification {
    padding: 20px 30px;

    @media (min-width: @screen-sm-min) {
        padding: 40px 50px;
    }

    .alert-heading {
        margin-bottom: 20px;
    }
}

// To allow for JS to be disabled, but having forms still work.
.no-js-visible {
    display: none;
    visibility: hidden;
}

.no-js {
    .no-js-visible {
        display: block !important;
        visibility: inherit;
    }

    .no-js-hidden {
        display: none;
        visibility: hidden;
    }
}

.row-eq-height {
    display: flex;
    flex-wrap: wrap;

    .content-box {
        height: 100%;
    }
}

.cell {
    &--one-line {
        white-space: nowrap;
    }
}

.demo-banner {
    width: 200px;
    background: #A94442;
    text-shadow: 0 1px darken(#A94442, 20%);
    font-weight: 700;
    position: fixed;
    z-index: 9999;
    top: 25px;
    left: -50px;
    text-align: center;
    line-height: 50px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    box-shadow: 0 0 5px rgba(0,0,0,.9);
    text-transform: uppercase;
    cursor: help;
}

.no-margin {
    margin: 0;

    &--bottom {
        margin-bottom: 0;
    }

    &--top {
        margin-top: 0;
    }
}

.font-weight-normal {
    font-weight: normal;
}

.cursor-pointer {
    cursor: pointer;
}

// Make a background colour variant for milk
.bg-milk {
    background-color: @milk-form-box;
}

// Make a foreground colour variant for milk
.text-milk {
    color: @milk-main;
}

.text-nowrap {
    white-space: nowrap;
}

.text-indent {
    margin-left: 10px;
}

.text-small-assistance {
    font-size: 85%;

    p {
        line-height: 1.7;
    }

    a {
        font-weight: 500;
        text-decoration: underline;
    }
}

// Styling for a data-toggle element
[data-toggle-text]:before {
    content: attr(data-toggle-text);
}

[data-toggle-text].collapsed:before {
    content: '';
}

.alert [data-toggle-text].pull-right {
    margin-left: 20px;
}

.inline {
    display: inline;
}

.row {
    &--footer {
        padding-top: 30px;
    }

    &--attribution {
        .font-size(1.2);
        padding: 10px 0 60px;
    }
}


dl.dl-horizontal {
    &--left-dt dt {
        text-align: left;
    }

    &--spaced dd {
        margin-bottom: 20px;
    }
}

.img {
    &--milk-bordered {
        border: 1px solid @milk-main;
    }
}

.no-border-top {
    border-top: 0 !important;
}
