// Selection of classes that can be applied for mobile styling
@media (max-width: @screen-sm-min) {
    .xs-text-strong {
        font-weight: 700;
    }

    .xs-text-sm {
        .font-size(1.4);
    }

    .xs-text-md {
        .font-size(1.6);
    }

    .xs-text-lg {
        .font-size(1.8);
    }

    .xs-text-lead {
        .font-size(2);

    }

    .xs-text-left {
        .text-left;
    }

    .xs-text-center {
        .text-center;
    }

    .xs-h1 {
        .h1;
        line-height: @line-height-computed * 1.5;

        &.xs-text-strong {
            font-weight: 700;
        }
    }

    .xs-h2 {
        .h2;
        line-height: @line-height-computed * 1.5;

        &.xs-text-strong {
            font-weight: 700;
        }
    }

    .xs-h3 {
        .h3;
        line-height: @line-height-computed * 1.5;

        &.xs-text-strong {
            font-weight: 700;
        }
    }

    .xs-spacer {
        .spacer(1);
    }

    .xs-spacer-sm {
        .spacer(0.5);
    }

    .xs-spacer-bottom {
        .spacer-bottom(1);
    }

    .xs-spacer-top {
        .spacer-top(1);
    }

    .xs-spacer-bottom-heavy {
        .spacer-top(1);
        .spacer-bottom(3);
    }

    .xs-list-inside {
        list-style-position: inside;
        padding-left: 0;

        li {
            .spacer(0.5);
        }
    }

    .xs-center-block {
        .center-block;
    }

    .xs-collapse:not(.collapsing) {
        .collapse;
    }

    .xs-tab-content.tab-content {
        background-color: transparent;
        padding-top: 0;
        padding-bottom: 0;

        .row  {
            .active-tab;
        }

        ul {
            padding-left: (@grid-gutter-width / 2);
        }
    }

    .xs-tab-pane.tab-pane {
        display: block;
    }

    .xs-tab-heading {
        .xs-spacer-bottom;
        .font-size(2);

        img {
            margin-right: 10px;
            position: relative;
            top: -3px;
        }
    }

    .xs-flex-disable {
        display: block !important;
    }

    .xs-pull-left {
        .pull-left;
    }

    .xs-btn-block {
        .btn-block;
    }

    .xs-flex-actions {
        flex-direction: column;
        width: 100%;

        > .btn:not(:first-child) {
            margin-top: 5px;
            margin-left: 0 !important;
        }
    }

    .xs-flex-wrap {
        flex-wrap: wrap;
    }

    .xs-flex-min-content {
        flex-basis: min-content;
    }
}
