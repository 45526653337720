.nav-tabs--themed {
    &>li>a,
    &>li>a:focus,
    &>li>a:hover {
        background-color: @milk-grey;
        color: @milk-main;
        border: 1px solid @milk-grey;
    }

    &>li.active>a,
    &>li.active>a:focus,
    &>li.active>a:hover {
        .active-tab;
    }
}

.active-tab() {
    background-color: @milk-light;
    color: @milk-main;
}

.tab-content {
    .active-tab;
    padding: @grid-gutter-width (@grid-gutter-width / 2);
    line-height: 2em;
}

.no-js {
    .nav-tabs {
        display: none;
    }

    .tab-pane {
        display: block;
    }
}

.xs-tab-heading {
    .indicator {
        transition: all 0.3s ease;
        transform: scale(1, -1);

        position: relative;
        top: 3px;
    }

    .collapsed .indicator {
        transform: scale(1, 1);
    }
}
