.home-hero {
    background: @milk-background-light-blue;
    color: @milk-background;

    h1, h2 {
        color: inherit;
    }
}

.home-sign-in {
    background: url('../img/dark-pattern.png');
    padding-bottom: 20px;

    @media (max-width: @screen-sm-min) {
        padding: 30px 20px 20px;
    }
}

.home-login-btn {
    margin: 3px 5px 0 0;
    float: left;
}

.home-hero__announcement {
    background: rgba(255, 185, 71, 0.9);
    color: @milk-main;
    padding: 15px;
    margin-bottom: 15px;
    line-height: 20px;

    h2 {
        font-weight: bold;
        .font-size(2);
        margin-top: 0px;
    }

    a {
        display: inline-block;
        padding: 8px 15px;
        border: 1px solid @milk-main;
        border-radius: 8px;
    }
}

.home-login-forgotten{
    float: right;
    font-weight: normal;
}

@media (min-width: @screen-sm-min) {
    .home-what-is {
        .spacer-top(2);
        .spacer-bottom(2.6);
    }

    .home-hero__announcement {
        margin-bottom: 0px;
    }
}

@media (min-width: @screen-md-min) {
    .home-hero {
        height: 365px;

        > div:first-child {
            background: url('../img/Nursery-Milk-Scheme-Home_Header-image.jpg');
        }
    }

    .home-hero__announcement {
        padding: 15px;
    }

    .home-hero-block {
        height: 100%;

        h1 {
            padding-left: 14%;
            margin-top: 35px;
        }
    }
}
