@import "chrome.less";
@import "sidebar.less";
@import "contact-details.less";
@import "claim.less";
@import "panel.less";
@import "anim.less";

#setting-container {
    padding: (@grid-gutter-width / 2) 0;

    h1, .h1 {
        font-weight: 700;
    }

    h1, h2, h3, h4, th {
        color: @headings-color;

        .small-link {
            float: right;
            padding-top: 5px;
        }
    }

    .small-link {
        .font-size(1.3);
    }
}

.setting-number-common() {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 5px;
    color: @milk-main;
}

.setting-number {
    .setting-number-common;
    .font-size(1.8);
    .visible-xs;

    span {
        font-weight: normal;
    }
}

.content-box {
    background-color: @milk-form-box;
    padding: 10px;

    &--spaced {
        margin: (@grid-gutter-width / 2) 0;
    }

    &--large-padding {
        padding: 30px;
    }

    &__text {
        padding: 5px;
    }
}

.auth-heading {
    .font-size(2);
}

.table {
    &--plain {
        width: 100%;

        td, th {
            padding: 5px 0;

            &.actions {
                .text-right;
                white-space: nowrap;
            }
        }
    }

    &--eq-cols {
        width: 100%;
        table-layout: fixed;

        td {
            width: 100%;
        }
    }

    &--milk {
        & > tbody > tr > td, & > thead > tr > th {
            border-color: @milk-form-box;
        }
    }

    &--pulled-left-inline {
        margin-left: -8px;
    }

    & tbody tr td&--indicator {
        width: 10px;
        padding-right: 0;
    }

    &--smallest {
        width: 1%;
        white-space: nowrap;
    }

    &--fixed-height {
        max-height: 500px;
        overflow-y: scroll;
        overflow-x: auto;
    }
}

.flex--align-center {
    display: flex;
    align-items: center;
}

.flex--grow {
    flex-grow: 2;
}

.flex {
    &--actions {
        display: flex;

        > .btn:not(:first-child) {
            margin-left: 5px;
        }
    }
}

.alert--spacer-top {
    .spacer-top(1);
}

.feedback {
    .content-box;
    margin-top: auto;
    margin-bottom: (@grid-gutter-width / 2);

    @media (min-width: @screen-sm-min) {
        line-height: 36px;
    }
}

hr.hr--light {
    border-top: 1px solid @milk-form-box;
}

.js-action-pending {
    overflow: hidden;
    position: relative;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    .action__text {
        position: relative;
        top: 0px;
        transition: all 0.2s ease-in-out;
    }

    .action__pending {
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: -30px;
        transition: all 0.2s ease-in-out;
        .text-center;
    }

    &.pending {
        opacity: 0.7;

        .action__pending {
            bottom: 5px;
        }

        .action__text {
            top: -30px;
        }

        // Offsets for smallest buttons
        &.btn-xs {
            opacity: 0.7;

            .action__pending {
                bottom: 0;
            }

            .action__text {
                top: -25px;
            }
        }
    }
}

.alert {
    &__actions {
        border-top: 1px solid;
        border-color: inherit;
        padding-top: 10px;
        margin-top: 10px;
    }
}

.title-with-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
