.sidebar-background(@img) {
    background: url('../../img/@{img}.svg') 15px center no-repeat;
}

.sidebar {
    .hidden-xs;

    &__content {
        background-color: @milk-form-box;
    }

    &__title {
        padding: 10px 0 10px 15px;
        font-weight: 700;
        .font-size(1.5);
        color: @headings-color;
    }

    @media (min-width: @screen-sm-min) {
        display: block;
    }

    .active {
        background-color: @milk-light;
    }

    &__overview {
        .sidebar-background(sidebar-overview);
    }

    &__claims {
        .sidebar-background(sidebar-claims);
    }

    &__contact {
        .sidebar-background(sidebar-contact);
    }

    &__faq {
        .sidebar-background(sidebar-faq);
    }

    &__payments {
        .sidebar-background(sidebar-payments);
    }

    &__ledger {
        .sidebar-background(sidebar-ledger);
    }

    &__setting {
        .sidebar-background(sidebar-setting);
    }

    &__user {
        .sidebar-background(sidebar-user);
    }

    &__users {
        .sidebar-background(sidebar-users);
    }

    &__password {
        .sidebar-background(sidebar-password);
    }

    &__manage {
        .sidebar-background(sidebar-manage);
    }

    &__message-centre {
        .sidebar-background(sidebar-message-centre);
    }

    .badge {
        color: #ffffff;
        background-color: @milk-main;
        border-radius: 5px;
        font-size: 18px;
    }
}

.sidebar__nav {
    padding-left: 0;
    list-style: none;

    li {
        a {
            padding: 10px 10px 10px 50px;
            display: block;

            &:hover {
                background-color: @milk-light;
                text-decoration: none;
            }
        }

        ul>li {
            &.active {
                background-color: @milk-form-border;
            }

            a {
                &:hover {
                    background-color: @milk-form-border;
                }
            }
        }
    }
}
