.panel-nmru {
    .panel-variant(@milk-form-border; @milk-main; @milk-light; @milk-form-border);
}

/**
 * Styles for the mobile variant of the FAQs.
 */
@media (max-width: @screen-sm-min) {
    .xs-panel-faq {
        &__heading {
            padding: 0;
        }

        &__toggle {
            display: block;
            padding: 15px 15px;

            &:active, &:hover, &:visited, &:focus {
                text-decoration: none;
            }
        }
    }
}
