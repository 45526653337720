.registration-form-block {
    background-color: @milk-form-box;
    padding: 20px;
    color: @milk-main;
    margin-bottom: 30px;

    legend {
        margin-bottom: 0;
    }
}

.registration-declaration {
    background-color: @milk-light;
    padding: 1px 15px;
    margin-bottom: 20px;
}

@media (min-width: @screen-md-min) {
    .registration-form-block {
        margin-bottom: 0;
    }
}
