.navbar__setting-title {
    display: inline-block;
    font-weight: 700;
    .font-size(2.6);
    height: 37px;

    position: absolute;
    left: 15px;
    top: 5px;
    right: 70px;

    .text-overflow;

    @media (min-width: @screen-sm-min) {
        position: relative;
        left: 0;
        right: 0;
        max-width: 340px;
    }

    @media (min-width: @screen-md-min) {
        max-width: 570px;
    }

    @media (min-width: @screen-lg-min) {
        max-width: 770px;
    }
}

.navbar__setting-number {
    .setting-number-common;
    .hidden-xs;
    .font-size(2);
    margin-left: (@grid-gutter-width / 2);

    @media (min-width: @screen-sm-min) {
        display: inline-block;
        position: relative;
        top: -5px;
    }
}

.navbar__mobile {
    a {
        background-image: none;
    }

    &.collapse {
        @media (min-width: @grid-float-breakpoint) {
            display: none !important;
        }
    }
}
