// Common chrome
.head-foot {
    background-color: @milk-main;
    color: @milk-background;

    a {
        color: @milk-background;
    }
}

footer.head-foot {
    padding: 20px 0;

    @media (min-width: @screen-sm-min) {
        padding: 0;
    }
}

// Heading specific
.head-bar {
    // Use long hand to prevent breaking .container class.
    padding-top: 5px;
    padding-bottom: 5px;

    @media (min-width: @screen-sm-min) {
        height: 115px;
        padding-top: 20px;
        padding-bottom: 15px;

        .row {
            display: flex;
            align-items: center;
        }
    }
}

.head-logo {
    margin: 0 auto;
    height: 40px;

    @media (min-width: @screen-sm-min) {
        margin: 0;
        height: auto;
    }
}

.head-contact {
    text-align: center;
    a {
        color: @milk-complementary;
    }

    @media (min-width: @screen-sm-min) {
        display: block;
        text-align: right;
    }
}

.head-tel {
    font-size: 26px;
}

.head-mail {
    font-size: 17px;
}

// Navigation specific
.navbar {
    background: @milk-background;
    color: @milk-main;
    margin-bottom: 0;

    li.active {
        background-color: @milk-light;
    }
}

.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-nav>li>ul>li>a {
    color: @milk-main;
    font-weight: 600;
}

.navbar-default .navbar-nav>li>a:hover {
    color: @milk-main;
    background-color: @milk-light;
}

.navbar-default .navbar-nav>.active>a {
    color: @milk-main;
    background-color: @milk-light;
}

.navbar-default .navbar-nav>li>ul>li.active,
.navbar-default .navbar-nav>li>ul>li>a:hover {
    color: @milk-main;
    background-color: @milk-medium;
}

.navbar-default .navbar-toggle {
    border-color: @milk-main;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: @milk-main;
}

@media (min-width: @screen-sm-min) {
    .navbar {
        border-radius: 0;
        margin-bottom: 0;
    }
}

// Footer specific
.footer-item {
    margin-right:20px;
    color: @milk-background;

    @media (min-width: @screen-sm-min) {
        height: 90px;
    }
}

.sitewide-announcement {
    background: darkorange;
    color: black;
    padding: 20px 0;
    text-align: center;

    &__title {
        font-size: 20px;
        margin-bottom: 10px;

        @media (min-width: @screen-sm-min) {
            margin-bottom: 0;
            text-align: left;
            line-height: 30px;
        }
    }

    &__description {
        margin-bottom: 10px;

        @media (min-width: @screen-sm-min) {
            margin-bottom: 0;
            line-height: 30px;
        }
    }

    &__cta {
        @media (min-width: @screen-sm-min) {
            line-height: 30px;
            text-align: right;
        }
    }

    &__link {
        color: black;
        text-decoration: underline;
    }
}
