.daisyicon--image(@img) {
    background: url('../img/@{img}.svg') left center no-repeat;
}

.daisyicon {
    position: relative;
    display: inline-block;
    height: 30px;
    width: 35px;

    &__setting {
        .daisyicon--image(sidebar-setting);
    }

    &__contact {
        .daisyicon--image(sidebar-user);
    }

    &__bank {
        .daisyicon--image(sidebar-payments);
    }

    &__agent {
        .daisyicon--image(sidebar-users);
    }
}

.message-centre-icon {
    padding-top: 4px;
    padding-left: 2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: #eee;

    &--important {
        .glyphicon-exclamation-sign();
        padding: 0;
        color: @milk-claim-purple;
        background-color: #ffffff;
        .font-size(2.4);
    }
}
