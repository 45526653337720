/**
 * Buttons at the bottom of a form.
 */
.form-actions {
    .spacer(1);

    .btn {
        margin-right: 5px;
        margin-bottom: 10px;

        // Ideally the margin would be removed from all buttons that do not
        // display on the last line, this isn't possible without forcing the
        // actions into blocks.

        @media (min-width: @screen-sm-min) {
            margin-bottom: 0;
        }
    }
}

/**
 * Actions at bottom of padded container.
 */
.form-actions--bottom {
    margin-bottom: 0;
}

.form--block {
    padding: 20px;
    background-color: @milk-form-box;
}

.form-control--bordered {
    border-color: @milk-complementary;
}

.form-group__no-label {
    padding-top: 22px;
}

.purchase-radio {
    margin-top: 5px;
}

.title-box-primary {
    .alert.alert-danger {
        background-color: #a94442;
        color: #fbfbfb;
        border: none;
    }

    .alert.alert--flash {
        font-size: 13px;
    }

    .form-group.has-error {
        background-color: #a94442;
        border-radius: 4px;
        padding: 5px 10px;

        label.control-label {
            color: #fbfbfb;
        }
        .help-block li {
            color: #fbfbfb;
        }
    }
}

/* For Firefox */
input[type=number]:disabled {
    -moz-appearance: textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type=number]:disabled::-webkit-inner-spin-button,
input[type=number]:disabled::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form {
    &--with-minidates {
        p.minidate {
            margin: 0;
            line-height: 0.3;
            font-size: 13px;
        }
    }
}

.help-block {
    color: #656565
}

// A legend usually has an underline and is larger text
// this makes it look like an inline form question
.legend--unstyled {
    padding: 0;
    margin-bottom: 0;
    font-size: inherit;
    font-weight: 700;
    color: inherit;
    border: 0;
}

.checkbox label.left-spacer {
    padding-left: 50px;

    > input {
        margin-left: -30px;
    }
}
