// @todo Change colours
.password-strength:before {
    display: block;
    content: ' ';
    height: 5px;
    width: 0;
    background-color: red;
    transition: width 1s;
    margin-bottom: 5px;
}

.password-strength-0:before {
    width: 5%;
}

.password-strength-1:before {
    width: 10%;
}

.password-strength-2:before {
    width: 30%;
}

.password-strength-3:before {
    background-color: orange;
    width: 60%;
}

.password-strength-4:before {
    background-color: green;
    width: 100%;
}
