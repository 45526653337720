.btn {
    border-width: 2px;
}

.btn-outline {
    .button-variant(@milk-main; @milk-button-outline-background; @milk-main);
}

.btn-next {
    .spacer-top(1);
}

.btn--spacer-top {
	.spacer-top(1);
}

.btn--no-padding-left {
    padding-left: 0;
}

.btn--panel-heading {
    position: relative;
    top: -4px;
    right: -8px;
    margin-left: 4px;
}

.alert__btn {
    .pull-right;
    .btn-sm;
    position: relative;
    top: -5px;
}

.input-group .btn {
    padding-top: 5px;
    padding-bottom: 5px;
}

.btn-disabled {
    .button-variant(@milk-form-box; darken(@milk-form-box, 10%); darken(@milk-form-box, 10%));
    cursor: not-allowed;
}
