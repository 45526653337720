// A variant of the standard panel that matches the milk colour scheme
.panel-milk {
    height: 100%;
    .panel-variant(lighten(@milk-form-border, 10%); @milk-main; @milk-form-box; lighten(@milk-form-border, 10%));

    & .panel-title {
        font-weight: 700;
    }

    .border-right {
        border-right: 1px solid @milk-form-box;
    }

    .border-left {
        border-left: 1px solid @milk-form-box;
    }
}

.panel-actions {
    padding: 10px 15px;
    border-top: 1px solid lighten(@milk-form-border, 10%);
}

.panel-body p:last-child {
    margin-bottom: 0;
}

.panel-heading__label {
    position: relative;
    top: -4px;
    line-height: 18px;

    background-color: @milk-main;

    &--arrow-right:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0);
        border-left-color: @milk-main;
        border-width: 6px;
        margin-top: -6px;
    }
}

// Normalise table padding to the panel title.
.panel .table {
    tr th:first-child, tr td:first-child {
        padding-left: 15px;
    }
}

.panel-tabbed {
    border-top: 0;
    padding-top: 20px;
}
