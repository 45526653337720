.claim-section-xs {
    border: 1px solid @milk-claim-purple;
    border-radius: 10px;
    margin-bottom: 10px;

    .row {
        padding: 15px;
        padding-bottom: 0;

        > div {
            margin-bottom: 10px;
        }
    }
}

.claim-status-icon {
    padding-top: 4px;
    padding-left: 2px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: #eee;

    &--completed {
        .glyphicon-ok();
        background-color: @milk-claim-purple;
    }

    &--incomplete {
        .glyphicon-exclamation-sign();
        padding: 0;
        color: @milk-claim-purple;
        background-color: #eee;
        .font-size(2.2);
    }

    &--danger {
        color: @milk-danger;
    }

    &--warning {
        color: @milk-complementary;
    }

    &--success {
        background-color: @milk-success;
    }
}

ul.claim-requirements {
    list-style: none;
    padding-left: 0;

    p {
        margin-bottom: 0;
    }
}

.claim-requirement-icon {
    width: 22px;
    height: 22px;
    padding: 0;
    margin-right: 10px;
    float: left;
    .font-size(2);

    &--completed {
        .glyphicon-ok();
        color: #3c763d;
    }

    &--main {
        .glyphicon-ok();
        color: @milk-main;
    }

    &--incomplete {
        .glyphicon-remove();
        color: #8a6d3b;
    }
}

.claim-view-section {
    .spacer-bottom;

    .claim-status-icon, .description {
        margin-right: 10px;
    }
}

.claim-proceed {
    .spacer-top(2.5);
    .font-size(1.8);

    .btn {
        margin-left: 10px;
    }
}

.claim--declaration-accept {
    margin: 30px 0;
    position: relative;

    .checkbox, label {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    &.has-error {
        color: @state-danger-text;
        background: @state-danger-bg;
        border: 1px solid @state-danger-border;
    }

    .claim--declaration-accept-checkbox {
        margin-right: 15px;
        margin-left: 10px;
    }

    .claim--declaration-label {
        margin-top: 10px;
        padding-left: 20px;
    }
}

.claim-date {
    display: block;
    text-align: center;
    font-weight: bold;
}

.ledger-resubmit-icon {
    .glyphicon-refresh();
    padding-top: 5px;
    padding-left: 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: #eee;
    background-color: @milk-claim-purple;
}

// Additional styling for claims in table rows
.table {
    &--resubmission {
        color: #a0a0a0;

        td {
            border-top-style: dotted;
            font-style: italic;
        }
    }

    &--resubmission + &--resubmission td {
        border-top: none;
    }
}

.submission-criteria-box {
    background-color: @milk-form-box;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 30px;
    width: 100%;

    div:first-child {
        flex-grow: 1;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        margin-top: 0;
    }

    ul.text-main {
        color: @milk-main;
        font-weight: 700;
    }

    li {
        margin-top: 10px;
    }
}

h2.form-heading {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.preview-frame {
    height: 500px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
}

.preview-image {
    margin-bottom: 20px;
    width: 100%;
}
